import { createContext, useState, useEffect, useContext, useRef, cloneElement } from 'react'
import moment from "moment"
import SportplacesContext from "./sportplacesContext"

const TimelineContext = createContext()

export const TimelineProvider = ({children}) => {
    const [selectedDate, setSelectedDate] = useState({});
    const [focusedItem, setFocusedItem] = useState()
    const { handleSportplaceSelect } = useContext(SportplacesContext);

    const handleTimelineDatesSelect = async (date) => {
      let convertedDate = moment(date).format("DD.MM.YYYY")
      handleSportplaceSelect(null, convertedDate)
    }


    return (
        <TimelineContext.Provider
          value={{
            selectedDate,
            focusedItem,
            setSelectedDate,
            setFocusedItem,
            handleTimelineDatesSelect
          }}
        >
          {children}
        </TimelineContext.Provider>
      )
}

export default TimelineContext

